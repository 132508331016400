function Share({ label, text, title }) {
    const canonical = document.querySelector("link[rel=canonical]");
    let url = canonical ? canonical.href : document.location.href;
    const shareDetails = { url, title, text };

    const handleSharing = async () => {
      try {
        let useriddd = localStorage.getItem("useridd");
        // Fetch the formatted game result from the PHP API
        const response = await fetch('https://tamilwordle.net/WordGameApi/get_game_result.php?userid='+useriddd);
        const data = await response.json();

        if (data.status === 'success') {
            const shareText = data.result_text;
            const title = 'Tamil Sol Game Result';
            const url = 'https://tamilwordle.net/?pfrom=share';

            // Check if Android sharing is available
            if (window.Android) {
                window.Android.share(title, shareText, url);
            } 
            // Fallback to Web Share API if supported
            else if (navigator.share) {
                await navigator.share({
                    title: title,
                    text: shareText,
                    url: url
                });
            } 
            // Fallback if sharing is not supported
            else {
              const shareText2 = `${title}\n\n${data.result_text}\n\n${url}`;
                // If sharing is not supported, copy the URL
                await navigator.clipboard.writeText(shareText2);
                alert('Sharing not supported. Link copied to clipboard.');
            }
        } else {
            alert('Failed to fetch game results.');
        }
    } catch (error) {
        console.error('Error fetching game results:', error);
    }
  };
    // const handleSharing = async () => {
    //   // Check if Web Share API is supported
    //   if (window.Android) {
    //       // Use the Android interface method to share content
    //       // Content for sharing (formatted text, emojis, and a link)
    //       const title = 'Tamil Sol Game Result';
    //       const text = 
    //           '#845 தமிழ் சொல் 3/6\n' +   // Game result title
    //           '🟨❌🟨❌🟨🟢\n' +           // First row with icons (Yellow, Red Cross, Green Circle)
    //           '🟢❌🟢❌🟨❌\n' +           // Second row
    //           '🟨🟢🟨🟢❌🟢\n' +           // Third row
    //           '🟢❌🟢❌🟢🟢\n' +           // Fourth row
    //           '❌🟢❌🟨🟢❌\n' +           // Fifth row
    //           '🟢🟨❌🟢🟨🟢\n\n' + 
    //           '#tamil #sol #சொல்\n'; // Hashtags
    //       const url = 'https://tamilwordle.net/?pfrom=share'; // Link

    //       // Call the Android share method, passing title, text, and URL
    //       window.Android.share(title, text, url);
    //   } else {
    //       alert('Share is not supported in this environment.');
    //   }
    // };
    return (
      <button className="sharer-button  share correct" onClick={handleSharing}>
        <span className="sharer-button-text">{label}</span>
      </button>
    );
  }
  export default Share;